@import '../scss/variables';
@import '../scss/fonts.scss';

// **** Prime React CSS **** //
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.scss';

// **** Fonts **** //
@import url('https://fonts.bunny.net/css2?family=Poppins:wght@300;400;500;600;700;800;900&family=Work+Sans:wght@300;400;500;600;700;800&display=swap');

body {
    margin: 0px;
    padding: 0px;
    font-family: $font-base;
    background: #FBFBFB;
        &::-webkit-scrollbar {
        width: 9px;
    }
    &::-webkit-scrollbar-track {
        background: $quaternary;
    }
    &::-webkit-scrollbar-thumb {
        background: $teritary;
        padding: 2px;
    }
}

.err-msg{
    color:red
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.p-checkbox .p-checkbox-box{
    border-radius: 0px;
}
.p-component{
    font-family: $font-secondary;
}
@mixin button { 
    padding: 24px 40px;
    box-shadow: 0px 8px 20px 0px rgba(28, 28, 28, 0.30);
    background: $btnprimary;
    border: none;
    margin-top: 2.5rem;
    border-radius: 0px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $btnprimaryover !important;
    }
} 

.choose-plans-button{
    @include button;
}
.p-inputtext{
    border: 1px solid $input-border !important;
    background-color: $input-field;
    border-radius: 0px;
    &::placeholder{
        color: $black !important;
        opacity: 0.5;
    }
}
.p-dropdown .p-dropdown-label{
    border: none !important;
}

@mixin transition { 
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.payment-loading{
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 7rem 0rem;
    .loading-block{
        text-align: center;
        p{
            margin: 5px 0px;
        }
    }
}

// **** Header Menu CSS **** //
.header-navigation{
    .p-menubar{
        background: transparent;
        border: none;
        padding: 0.5rem 0;
        .p-menubar-start{
            .logo{
                img{
                    width: 100%;
                    max-width: 194px;
                }
            }
        }
        .p-menubar-root-list{
            margin-left: auto;
            .p-menuitem{
                margin: 0px 3px;
                .p-menuitem-link{
                    padding: 0.75rem 1.15rem;
                    .p-menuitem-text{
                        font-size: 18px;
                        color: $black;
                        font-family: $font-base;
                    }
                    &:hover{
                        background: $btnprimaryover !important;
                        border-radius: 0px;
                        .p-menuitem-text,.p-menuitem-icon{
                            color: $white !important;
                        }
                    }
                    &:focus{
                        box-shadow: none;
                    }
                }
                &.p-menuitem-active{
                    .p-menuitem-link{
                        background: $mainmenu;
                        border-radius: 0px;
                        .p-menuitem-text{
                            color: $white;
                        }
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
            }
            .p-button{
                padding: 0px;
                background: none;
                border: none;
                .p-menuitem-link{
                    padding: 20px 50px;
                    border-radius: 50px;
                    background: rgba(255, 255, 255, 0.20);
                    backdrop-filter: blur(15px);
                    margin: 0px 0px;
                    border: 1px solid #b8d1db;
                    .p-menuitem-text{
                        font-family: $font-base;    
                        font-size: 18px;
                        font-weight: 600;
                    }
                    &:hover{
                        background: $primary;
                    }
                }
                &.login-btn{
                    margin-left: 2rem;
                }
            }
        }
        .p-menubar-end{
            .user-button{
                min-height: 62px;
                background: $teritary !important;
                width: 62px;
                padding: 0px !important;
                border: none;
                &:hover{
                    background: $secondary !important;
                }
            }
            .p-dropdown{
                padding: 5px 14px;
                border-radius: 0px;
                background: $white;
                backdrop-filter: blur(15px);
                margin: 0px 10px;
                border: 1px solid $black;
                .p-dropdown-label{
                    font-family: $font-base;
                    font-size: 18px;
                    font-weight: 600;
                    color: $black;
                }
                .p-dropdown-trigger{
                    padding: 0px;
                    width: 1rem;
                    .p-dropdown-trigger-icon{
                        color: $black;
                    }
                }
            }
            .p-button{
                padding: 15px 15px;
                border-radius: 0px;
                background: $btnprimaryover;
                backdrop-filter: blur(15px);
                margin: 0px 8px;
                border: none;
                min-width: 120px;
                .p-button-label{
                    font-family: $font-base;
                    font-size: 18px;
                    font-weight: 600;
                }
                &:hover{
                    background: $btnsecondaryover;
                }
                &.user-button{
                    min-width: auto;
                }
            }
            b{
                color: $secondary;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 180px;
                white-space: nowrap;
                text-align: left;
            }
        }
    }
}
.account-menu-dropdown{
    margin: 1rem 0px 0px;
    padding: 0px;
    border-radius: 0px;
    .p-menu-list{
        .p-menuitem{
            font-size: 15px;
            .p-menuitem-link{
                background: $white;
                .p-menuitem-text{
                    color: $secondary;
                    font-family: $font-secondary;
                }
                .p-menuitem-icon{
                    font-size: 14px;
                    color: $secondary;
                }
                &:hover{
                    background: $teritary;
                    .p-menuitem-text{
                        color: $white;
                    }
                    .p-menuitem-icon{
                        color: $white;
                    }
                }
            }
        }
    }
    &:before{
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: $white;
        top: -6px;
        left: 25px;
        transform: rotate(45deg);
    }
}
.p-dropdown-panel{
    margin-top: 1rem;
    .p-dropdown-items-wrapper{
        border-radius: 0px;
        .p-dropdown-items{
            margin: 0px;
            padding: 0px;
            .p-dropdown-item{
                background: $white;
                font-size: 15px;
                padding: 0.65rem 1.25rem;
                &.p-highlight{
                    background: $teritary;
                    color: $white;
                }
                &:hover{
                    background: $teritary;
                    color: $white;
                }
            }
        }
    }
    &.language-dropdown {
        display: block !important;
        position: fixed;
        top: 75px !important;
        z-index: 99999 !important;
        .p-dropdown-items-wrapper{
            max-height: 225px !important;
        }
    }
}
.overlay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(40, 50, 73, 0.75);
    z-index: 9999;
    .loading-block{
        text-align: center;
        color: $white;
    }
}
.p-dialog{
    .p-dialog-content{
        .paypal-section-button{
            width: 325px;
        }
    }
}
.payment-iframe{
    border: 1px solid #ddd;
    border-radius: 10px;
    height: 650px;
    margin-top: 30px;
}
.is-sticky{
    header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        background:$primary;
        padding:15px 165px;
        -webkit-animation-name: animationFade;
        animation-name: animationFade;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.25);
        box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.25);
    }
    .fixed-height{
        height: 80px;
    }
   .header-navigation .p-menubar .p-menubar-end .p-button{
        background: $btnprimaryover;
        &:hover{
            background: $btnprimary;
        }
    }
}
.aboutus-header-section{
    .is-sticky{
        .fixed-height{
            height: 111px;
        }
    }
}

.p-toast{
    opacity: 1;
    max-width: 26rem;
    .p-toast-message{
        &.p-toast-message-success{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #2BA079;
                border-radius: 2px;
            }
            .p-toast-message-content{
                .p-toast-message-icon{
                    background:#2BA079 !important;
                }
            }
        }
        &.p-toast-message-error{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #ff5757;
                border-radius: 2px;
            }
            .p-toast-message-content{
                .p-toast-message-icon{
                    background:#ff5757 !important;
                }
            }
        }
        &.p-toast-message-warn{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #cc8925;
                border-radius: 2px;
            }
            .p-toast-message-content{
                .p-toast-message-icon{
                    background:#cc8925 !important;
                }
            }
        }
        &.p-toast-message-info{
            background:$white;
            color: $black;
            border: none;
            position: relative;
            &::before{
                content: "";
                width: 4px;
                height: 80%;
                position: absolute;
                left: 8px;
                top: 8px;
                background: #5774cd;
                border-radius: 2px;
            }
            .p-toast-message-content{
                .p-toast-message-icon{
                    background:#5774cd !important;
                }
            }
        }
        .p-toast-message-content{
            padding: 12px;
            padding-left: 25px;
            align-items: center;
            .p-toast-message-icon{
                width: 25px;
                height: 25px;
                border-radius: 30px;
                color: $white;
                font-size: 14px;
                text-align: center;
                line-height: 18px;
                min-width: 25px;
                padding: 5px;
            }
            .p-toast-message-text{
                .p-toast-summary{
                    font-size: 14px;
                    font-weight: 500;
                }
                .p-toast-detail{
                    font-size: 15px;
                    margin: 0.4rem 0 0 0;
                    color: #505050;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .p-toast-icon-close{
            width: auto;
            height: auto;
            color: #505050 !important;
            font-size: 14px;
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 9;
            .p-toast-icon-close-icon{
                font-size: 13px;
            }
        }
    }
}

// **** Footer CSS **** //
footer{
    position: relative;
    .footer-info{
        padding: 50px 165px;
        background: $primary;
        border-top: 1px solid #E5E5E5;
        .footer-links{
            font-family: $font-secondary;
            a{
                display: table;
                margin-bottom: 20px;
                font-size: 18px;
                color: $black;
                opacity: 0.6;
                position: relative;
                @include transition;
                &:hover{
                    opacity: 1;
                    padding-left: 12px;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        background: $teritary;
                        left: 0px;
                        top: 10px;
                        border-radius: 0px;
                    }
                }
                .whatsapp-img{
                    display: inline-block;
                    line-height: 0px;
                    img{
                        width: 22px;
                    }
                }
                &.opacity{
                    opacity: 1;
                    .number{
                        opacity: 0.6;
                    }
                    &:hover{
                        .number{
                            opacity: 1;
                        }
                    }
                }
            }
            p{
                opacity: 0.6;
                color: $black;
            }
        }
        .footer-logo{
            p{
                margin-top: 3rem;
                margin-bottom: 2rem;
                a{
                    font-size: 18px;
                    color: $black;
                    opacity: 0.6;
                }
            }
            ul{
                margin: 12px 0px 0px;
                padding: 0px;
                li{
                    list-style: none;
                    display: inline-block;
                    margin-right: 10px;
                    .social-image{
                        width: 26px;
                        height: 26px;
                        display: block;
                        border-radius: 2px;
                        @include transition;
                        background-size: 26px !important;
                        &.facebook{
                            background: url('../images/facebook.svg');
                        }
                        &.instagram{
                            background: url('../images/instagram.svg');
                        }
                        &.youtube{
                            background: url('../images/youtube.svg');
                        }
                        &:hover{
                            background-position: 0px -27px;
                        }
                    }
                }
            }
            .social-span{
                font-size: 14px;
                font-family: $font-secondary;
                color: $black;
                font-weight: 500;
                display: block;
                margin-top: 3rem;
            }
            .logo{
                img{
                    width: 100%;
                    max-width: 225px;
                }
            }
        }
        h2{
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 30px;
            margin-top: 10px;
        }
            }
    .copyrights-div{
        padding: 50px 165px;
        background: $secondary;
        p{
            margin: 0px;
            color: $white;
            font-size: 14px;
            font-weight: normal;
            font-family: $font-secondary;
            a{
                color: $white;
                &:hover{
                    color: $teritary;
                }
            }
        }
        span{
            font-size: 10px;
            font-family: $font-secondary;
            color: $white;
        }
        ul{
            margin: 0px; 
            padding: 0px;
            li{
                list-style: none;
                margin: 0px 3px;
            }
        }
    }
    .scroll-to-top{
        position: fixed;
        right: 2.5rem;
        bottom: 3.5rem;
        background: $teritary !important;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0px;
        .p-scrolltop-icon{
            color: $white;
            font-size: 14px;
        }
    }
}
.pac-container{
    z-index: 9999;
}
.p-tooltip{
    .p-tooltip-text{
        padding: 0.4rem 0.75rem;
        background: $primary;
    }
    .p-tooltip-arrow{
        margin-left: -0.35rem;
        border-width: 0 0.35em 0.35rem;
    }
}

.custom-phoneno-field {
    .react-tel-input{
        font-family: $font-secondary;
        .form-control {
            border-radius: 8px;
            border: 1px solid $input-border !important;
            background: $input-field;
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            width: 100%;
            font-family: $font-secondary;
        }
        .flag-dropdown{
            border-radius: 8px 0 0 8px;
            .selected-flag{
                border-radius: 8px 0 0 8px;
            }
        }
    }
}

.payment_summary_header {
    min-height: auto !important;
    background: none !important;
}

// Updated on 04-03-2025
.terms-conditions{
    word-wrap: break-word;
    ol{
        li[data-list="bullet"]{
            list-style: disc;
        }
        li{
            padding: 5px 0px;
        }
    }
    p{
        br{
            display: none;
        }
    }
}
.p-accordion .p-accordion-content{
    ol{
        li[data-list="bullet"]{
            list-style: disc;
        }
    }
    p{
        br{
            display: none;
        }
    }
    .ql-indent-1 {
        padding-left: 30px;
    }
    .ql-indent-2 {
        padding-left: 60px;
    }
    .ql-indent-3 {
        padding-left: 90px;
    }
}
.terms-and-conditions-section{
    word-wrap: break-word;
    ol{
        li[data-list="bullet"]{
            list-style: disc;
        }
        li{
            padding: 5px 0px;
        }
    }
    p{
        br{
            display: none;
        }
    }
    .ql-indent-1 {
        padding-left: 30px;
    }
    .ql-indent-2 {
        padding-left: 60px;
    }
    .ql-indent-3 {
        padding-left: 90px;
    }
}
// Updated on 04-03-2025

.p-checkbox:not(.p-checkbox-disabled){
    .p-checkbox-box{
        &.p-focus{
            box-shadow: none;
        }
        &:hover{
            border-color: $teritary;
        }
        &.p-highlight{
            border-color: $teritary;
            background: $teritary;
            &:hover{
                border-color: $secondary;
                background: $secondary;
            } 
        }
    }
}

@media (min-width:1200px) and (max-width:1460px){
    .header-navigation{
        .p-menubar{
            .p-menubar-root-list{
                .p-menuitem{
                    .p-menuitem-link{
                        padding: 0.75rem 12px;
                        .p-menuitem-text{
                            font-size: 15px;
                        }
                    }
                    &.p-button{
                        margin: 0px 3px;
                        .p-menuitem-link{
                            padding: 20px 30px;
                        }
                    }
                }
            }
            .p-menubar-end{
                .p-button{
                    padding: 18px 15px;
                    margin: 0px 5px;
                    min-width: auto;
                    .p-button-label{
                        font-size: 16px;
                    }
                }
                .user-button{
                    min-height: 60px;
                    width: 60px;
                }
                .p-dropdown{
                    margin: 0px 8px;
                    padding: 5px 14px;
                    .p-dropdown-label{
                        font-size: 16px; 
                    }
                }
            }
            .p-menubar-start{
                .logo{
                    img{
                        max-width: 125px;
                    }
                }
            }
        }
    }
    footer{
        .footer-info, .copyrights-div{
            padding: 2rem 40px;
        }
    }
    .aboutus-header-section{
        .is-sticky{
            .fixed-height{
                height: 101px;
            }  
        }
    }
}
@media (max-width:960px){
    .header-navigation{
        .p-menubar{
            .p-menubar-button{
                width: 2.6rem;
                height: 2.6rem;
                background: $secondary;
                position: absolute;
                top: 13px;
                right: 0px;
                border-radius: 0%;
                .pi-bars{
                    color: $white;
                }
            }
            .p-menubar-root-list{
                padding: 15px;
                border-radius: 5px;
                width: 100%;
                max-width: 320px;
                right: 0px;
                .p-menuitem{
                    margin: 1px 0;
                    .p-menuitem-link{
                        .p-menuitem-text{
                            font-size: 16px;
                        }
                    }
                    &.p-button {
                        margin-top: 10px;
                        .p-menuitem-link{
                            padding: 8px 30px;
                            min-width: 135px;
                            display: block;
                            margin-left: 1rem;
                        }
                    }
                    &.login-btn{
                        margin-left: 0px;
                        margin-top: 5px;
                    }
                }
            }
            .p-menubar-end{
                text-align: center;
                margin-right: 3rem;
                .p-dropdown{
                    padding: 0px 10px;
                    margin: 0px 5px;
                    .p-dropdown-label{
                        font-size: 16px;
                        padding: 0.5rem 0.75rem;
                    }
                }
                .p-button{
                    margin: 0px 5px;
                    padding: 12px 15px;
                    .p-button-label{
                        font-size: 16px;
                    }
                }
                .user-button{
                    min-height: 50px;
                    width: 50px;
                }
            }
        }
    }
    footer{
        .footer-info, .copyrights-div{
            padding: 25px 20px;
            h2{
                font-size: 18px;
            }
            .footer-links{
                a,p{
                    font-size: 15px;
                    margin-bottom: 15px;
                }
            }
        }
        .copyrights-div{
            justify-content: center !important;
            display: block;
            text-align: center;
            P{
                margin-bottom: 10px;
            }
            ul{
                justify-content: center;
            }
        }
        .scroll-to-top{
            right: 1rem;
            bottom: 3rem;
            width: 2rem;
            height: 2rem;
        }
    }
    .aboutus-header-section{
        .is-sticky{
            .fixed-height{
                height: 90px;
            }  
        }
    }
}
@media (min-width:769px) and (max-width:960px){
    footer{
        .copyrights-div{
            justify-content: space-between !important;
            p{
                margin-bottom: 0px;
            }
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    footer{
        .footer-info, .copyrights-div{
            padding: 25px 20px;
        }
        .footer-info .footer-logo .logo img {
            width: 100%;
            max-width: 185px;
        }
    }
    .header-navigation{
        .p-menubar{
            .p-menubar-start{
                .logo{
                    img{
                        max-width: 115px;
                    }
                }
            }
            .p-menubar-root-list{
                .p-menuitem{
                    margin: 3px;
                    .p-menuitem-link{
                        padding: 0.75rem 0.6rem;
                        .p-menuitem-text{
                            font-size: 15px;
                        }
                    }
                    &.p-button{
                        margin: 0px 3px;
                        .p-menuitem-link{
                            padding: 14px 25px;
                        }
                    }
                }
            }
            .p-menubar-end{
                text-align: center;
                .p-dropdown{
                    padding: 0px 10px 0px 4px;
                    margin: 0px 3px;
                    .p-dropdown-label{
                        font-size: 14px;
                                            }
                }
                .p-button{
                    margin: 0px 3px;
                    padding: 12px 14px;
                    .p-button-label{
                        font-size: 14px;
                    }
                }
                .user-button{
                    min-height: 50px;
                    width: 50px;
                }
            }
        }
    }
    .aboutus-header-section{
        .is-sticky{
            .fixed-height{
                height: 90px;
            }  
        }
    }
}
@media (max-width:961px){
    .header-navigation{
        .p-menubar{
            .p-menubar-start{
                .logo{
                    img{
                        max-width: 120px;
                    }
                }
            }
            .p-menubar-end{
                .p-dropdown{
                    margin: 5px 3px;
                }
                .p-button{
                    margin: 5px 3px;
                }
            }
                    }
    }
    .p-dialog{
        .p-dialog-content{
            .paypal-section-button{
                width: 100%;
                max-width: 325px;
            }
        }
    }
    footer{
        .footer-info .footer-logo .logo img {
            width: 100%;
            max-width: 185px;
        }
    }
}
@media (max-width: 768px) {
    .payment-iframe {
      height: 450px;
      margin-top: 20px;
    }
    .p-toast{
        width: 95%;
        left: 0px;
        right: 0px !important;
        margin: 0 auto;
        top: 10px !important;
    }
    .terms-and-conditions-section{
        .ql-indent-1 {
            padding-left: 20px;
        }
        .ql-indent-2 {
            padding-left: 40px;
        }
        .ql-indent-3 {
            padding-left: 60px;
        }
    }
    .p-accordion .p-accordion-content{
        .ql-indent-1 {
            padding-left: 20px;
        }
        .ql-indent-2 {
            padding-left: 40px;
        }
        .ql-indent-3 {
            padding-left: 60px;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1360px) {
    .payment-iframe {
      height: 600px;
      margin-top: 20px;
    }
}

.green-dot {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 10px;
}

@media (min-width:1460px) and (max-width:1580px){
    .header-navigation{
        .p-menubar{
            .p-menubar-root-list{
                .p-menuitem{
                    .p-menuitem-link{
                        padding: 0.75rem 18px;
                        .p-menuitem-text{
                            font-size: 16px;
                        }
                    }
                    &.p-button{
                        margin: 0px 3px;
                        .p-menuitem-link{
                            padding: 20px 30px;
                        }
                    }
                }
            }
            .p-menubar-end{
                .p-button{
                    padding: 18px 25px;
                    margin: 0px 5px;
                    .p-button-label{
                        font-size: 16px;
                    }
                }
                .user-button{
                    min-height: 60px;
                    width: 60px;
                }
                .p-dropdown{
                    margin: 0px 8px;
                    padding: 5px 14px;
                    .p-dropdown-label{
                        font-size: 16px; 
                    }
                }
            }
        }
    }
    .steps-section .relative .steps-box h3{
        font-size: 20px;
        line-height: 30px;
    }
}
@media (min-width:1200px) and (max-width:1230px){
    .header-navigation .p-menubar .p-menubar-root-list .p-menuitem .p-menuitem-link {
        padding: 0.75rem 10px;
    }
    .header-navigation .p-menubar .p-menubar-end .p-button{
        padding: 18px 15px;
    }
    .header-navigation .p-menubar .p-menubar-end .p-dropdown {
        margin: 0px 5px;
        padding: 5px 12px;
    }
}


.asterisk-unlimited {
    color: $error-msg;
}
.tooltip-block{
    width: 100%;
    max-width: 225px;
    font-size: 13px;
    line-height: 140%;
}